export default {
    loginSuccess: (state, user) => {
        state.status.loggedIn = true;
        let users = JSON.parse(user)
        state.isPresent = true
        state.user = { ...state.user, ...users }
        console.log(state.user)
        localStorage.setItem('userData', JSON.stringify(state.user))
        state.loading = false
    },
    loginFailure: (state) => {
        state.status.loggedIn = false;
        state.user = null;
        state.loading = false
        state.isPresent = false
    },

    registerSuccess: (state) => {
        state.status.loggedIn = false;
        state.loading = false
    },
    registerFailure: (state) => {
        state.status.loggedIn = false;
        state.loading = false
    },
    updateUser: (state, user) => {
        state.isPreseent = true;
        state.user.name = user
        sessionStorage.setItem("userData", JSON.stringify(state.user))

    }

}
