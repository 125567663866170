<template>
  <router-link to="/">
    <svg width="117" height="22" viewBox="0 0 117 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0)">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.71922 3.41706L10.5924 15.7597H0.560181V20.4681H16.1951L13.1102 3.76374L12.9055 2.57553L7.71922 3.41706Z" fill="#FF7A01"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M26.5341 7.97312C27.2669 9.45715 27.3152 11.4931 26.3201 13.2993L22.4369 20.4752H27.5369L30.4142 15.5817C32.1551 12.422 32.144 8.74975 30.7264 5.87879C29.2764 2.94209 26.3107 0.816456 22.4369 1.12433C20.3274 1.29199 18.1362 1.65129 15.6127 2.16988L16.5497 6.78296C18.9775 6.28403 20.962 5.9646 22.8056 5.81808C24.5585 5.67876 25.8338 6.55485 26.5341 7.97312Z" fill="#FF7A01"/>
        <path d="M16.6637 6.77312L12.287 7.80351L12.7215 2.60752L15.953 2.10092L16.6637 6.77312Z" fill="#FF7A01"/>
        <path d="M42.4747 19.5062V2.06372H45.5756V12.3596L49.8393 7.49027H53.5216L48.6038 12.9653L54.3211 19.5062H50.445L45.5756 13.474V19.5062H42.4747Z" fill="#514A41"/>
        <path d="M54.3056 19.5062V7.49027H57.0431L57.2853 9.52522C57.6568 8.8146 58.1897 8.24934 58.8842 7.82943C59.5948 7.40952 60.4266 7.19956 61.3794 7.19956C62.8653 7.19956 64.02 7.66792 64.8437 8.60465C65.6674 9.54137 66.0792 10.9142 66.0792 12.723V19.5062H62.9783V13.0137C62.9783 11.9801 62.7684 11.1887 62.3485 10.6396C61.9286 10.0905 61.2745 9.81593 60.3862 9.81593C59.5141 9.81593 58.7954 10.1228 58.2301 10.7365C57.681 11.3502 57.4064 12.2062 57.4064 13.3044V19.5062H54.3056Z" fill="#514A41"/>
        <path d="M72.9447 19.7969C71.7335 19.7969 70.6595 19.5385 69.7227 19.0217C68.786 18.5049 68.0512 17.7781 67.5182 16.8414C66.9852 15.9046 66.7187 14.8226 66.7187 13.5951C66.7187 12.3515 66.9772 11.2452 67.494 10.2762C68.0269 9.30719 68.7537 8.5562 69.6743 8.02323C70.611 7.47412 71.7092 7.19956 72.969 7.19956C74.1479 7.19956 75.1897 7.45797 76.0941 7.97478C76.9985 8.49159 77.701 9.20221 78.2017 10.1066C78.7185 10.9949 78.9769 11.9882 78.9769 13.0864C78.9769 13.264 78.9689 13.4498 78.9527 13.6436C78.9527 13.8374 78.9446 14.0393 78.9285 14.2492H69.7954C69.86 15.186 70.183 15.9208 70.7644 16.4538C71.362 16.9867 72.0807 17.2532 72.9205 17.2532C73.5504 17.2532 74.0753 17.1159 74.4952 16.8414C74.9312 16.5507 75.2543 16.1792 75.4642 15.727H78.6135C78.3874 16.4861 78.0079 17.1805 77.4749 17.8104C76.9581 18.4241 76.3121 18.9086 75.5369 19.2639C74.7778 19.6192 73.9138 19.7969 72.9447 19.7969ZM72.969 9.71903C72.2099 9.71903 71.5397 9.93706 70.9582 10.3731C70.3768 10.793 70.0054 11.439 69.8439 12.3112H75.8276C75.7791 11.5198 75.4884 10.8899 74.9555 10.4216C74.4225 9.95321 73.7603 9.71903 72.969 9.71903Z" fill="#514A41"/>
        <path d="M85.4853 19.7969C84.274 19.7969 83.2 19.5385 82.2633 19.0217C81.3266 18.5049 80.5917 17.7781 80.0588 16.8414C79.5258 15.9046 79.2593 14.8226 79.2593 13.5951C79.2593 12.3515 79.5177 11.2452 80.0345 10.2762C80.5675 9.30719 81.2943 8.5562 82.2148 8.02323C83.1516 7.47412 84.2498 7.19956 85.5095 7.19956C86.6885 7.19956 87.7302 7.45797 88.6346 7.97478C89.5391 8.49159 90.2416 9.20221 90.7423 10.1066C91.2591 10.9949 91.5175 11.9882 91.5175 13.0864C91.5175 13.264 91.5094 13.4498 91.4933 13.6436C91.4933 13.8374 91.4852 14.0393 91.469 14.2492H82.336C82.4006 15.186 82.7236 15.9208 83.305 16.4538C83.9026 16.9867 84.6213 17.2532 85.4611 17.2532C86.0909 17.2532 86.6158 17.1159 87.0357 16.8414C87.4718 16.5507 87.7948 16.1792 88.0048 15.727H91.1541C90.928 16.4861 90.5485 17.1805 90.0155 17.8104C89.4987 18.4241 88.8527 18.9086 88.0774 19.2639C87.3184 19.6192 86.4543 19.7969 85.4853 19.7969ZM85.5095 9.71903C84.7505 9.71903 84.0802 9.93706 83.4988 10.3731C82.9174 10.793 82.5459 11.439 82.3844 12.3112H88.3682C88.3197 11.5198 88.029 10.8899 87.496 10.4216C86.9631 9.95321 86.3009 9.71903 85.5095 9.71903Z" fill="#514A41"/>
        <path d="M92.1875 19.5062V7.49027H94.925L95.1672 9.52522C95.5387 8.8146 96.0717 8.24934 96.7661 7.82943C97.4767 7.40952 98.3085 7.19956 99.2614 7.19956C100.747 7.19956 101.902 7.66792 102.726 8.60465C103.549 9.54137 103.961 10.9142 103.961 12.723V19.5062H100.86V13.0137C100.86 11.9801 100.65 11.1887 100.23 10.6396C99.8105 10.0905 99.1564 9.81593 98.2681 9.81593C97.396 9.81593 96.6773 10.1228 96.112 10.7365C95.5629 11.3502 95.2884 12.2062 95.2884 13.3044V19.5062H92.1875Z" fill="#514A41"/>
        <path d="M104.988 19.5062V2.06372H108.089V12.3596L112.353 7.49027H116.035L111.117 12.9653L116.835 19.5062H112.959L108.089 13.474V19.5062H104.988Z" fill="#514A41"/>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="116.843" height="20.0302" fill="white" transform="translate(0 0.984894)"/>
        </clipPath>
      </defs>
    </svg>
  </router-link>

</template>

<script>
export default {
  name: "Logo"
}
</script>

<style scoped>

</style>