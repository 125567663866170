import Vue from "vue";
import axios from "axios";
import {logout} from "@/services/auth-services";

const injectAxios = () => {
  const _axios = axios.create({
    baseURL: 'https://kneenk-powerhouse.herokuapp.com'
  })
  _axios.interceptors.request.use((config) => {
    const token = JSON.parse(localStorage.getItem('userToken'))
    console.log({token})
    if (token) {
      config.headers.Authorization = `Bearer ${token.access}`
    }
    return config
  })

  _axios.interceptors.response.use((response => {
    return Promise.resolve(response)
  }), (error) => {
    const UNAUTHORIZED_STATUS_CODE = 401
    const status = error?.response?.status
    if (status === UNAUTHORIZED_STATUS_CODE) {
      if (!'/login'.includes(location.pathname)) {
        // logout()
      }
    }
    return Promise.reject(error)
  })
  Vue.prototype.$axios = _axios
}

export default injectAxios
