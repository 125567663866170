let timeout

export default {
  SHOW_SNACKBAR: (state, payload) => {

    state.showSnackBar = true;
    state.success = payload.success;
    state.snackBarMessage = payload.snackBarMessage;
    state.snackBarHint = payload.snackBarHint
    if (payload.color !== undefined) {

      state.color = payload.color;
    }

    timeout = setTimeout(() => {
      state.showSnackBar = false
      state.snackBarMessage = "";
      state.snackBarHint = ""
      console.log("snackbar timeout")
    }, payload.timeout || 5000)

  },
  TOGGLE_SNACKBAR: (state, value) => {
    state.showSnackBar = value
    state.snackBarMessage = "";
    clearTimeout(timeout)
  }
};
