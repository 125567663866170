const getUserData = () => {
  return {
    fromLocalStorage: () => JSON.parse(localStorage.getItem('userData'))
  }
}

export const updateUserData = (data) => {
  const existingData = getUserData().fromLocalStorage()
  let updatedData = data
  if (existingData) {
    updatedData = {
      ...existingData,
      ...data
    }
  }
  localStorage.setItem('userData', JSON.stringify(updatedData))
}

export default getUserData
