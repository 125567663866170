<template>
  <v-alert
    min-width="300px"
    :value="showSnackBar"
    class="mt-3 snackbar"
    :class="mobileSnackbar"
  >
    <div class="d-flex">
      <div>
        <svg v-if="success" width="21" height="29" viewBox="0 0 21 29" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M10.8594 22.9092C15.2777 22.9092 18.8594 19.3275 18.8594 14.9092C18.8594 10.4909 15.2777 6.90918 10.8594 6.90918C6.4411 6.90918 2.85938 10.4909 2.85938 14.9092C2.85938 19.3275 6.4411 22.9092 10.8594 22.9092ZM14.5665 13.6163C14.957 13.2258 14.957 12.5926 14.5665 12.2021C14.176 11.8115 13.5428 11.8115 13.1523 12.2021L9.85938 15.495L8.56648 14.2021C8.17596 13.8115 7.54279 13.8115 7.15227 14.2021C6.76174 14.5926 6.76174 15.2258 7.15227 15.6163L9.15227 17.6163C9.54279 18.0068 10.176 18.0068 10.5665 17.6163L14.5665 13.6163Z" fill="#00A44B"/>
        </svg>
      </div>
     <div class="ml-3">
       <p class="mt-1 body-message mb-0">{{ snackBarMessage }}</p>
       <small>{{  snackBarHint }}</small>
     </div>
    </div>
<!--    <v-icon-->
<!--      color="white"-->
<!--      small-->
<!--      class="close_icon"-->
<!--      @click.prevent="closeSnackbar"-->
<!--      id="close"-->
<!--    >-->
<!--      mdi-close-->
<!--    </v-icon>-->
  </v-alert>
</template>

<script>
import { mdiCloseCircle } from "@mdi/js";
// import { mdiCheckCircle } from "@mdi/js";
import { mapActions } from "vuex";

export default {
  name: "SnackBar",
  data() {
    return {
      background_color: "red",
      img: mdiCloseCircle,
    };
  },
  props: {
    showSnackBar: {
      type: Boolean,
    },
    success: {
      type: Boolean,
    },
    snackBarMessage: {
      type: String,
    },
    alternateColor: String,
    snackBarHint: String
  },
  methods: {
    ...mapActions("snackBar", ["closeSnackBar"]),
    closeSnackbar() {
      this.closeSnackBar();
    },

  },
  computed: {
    getSnackBarColor() {
      if (this.success === true) {
        return (this.background_color = "teal");
      } else if (!this.success && this.alternateColor !== undefined) {
        return (this.background_color = "amber");
      } else {
        return (this.background_color = "red");
      }
    },
    getSnackBarIcon() {
      return `mdi-bell-circle`;
    },
    mobileSnackbar() {
      return {
        'snackbar--success': this.success,
        'snackbar--failed': !this.success
      }
    },
  },
};
</script>

<style scoped lang="scss">
.snackbar {
  display: flex;
  background: #FFFFFF !important;
  border: 1px solid #F8F8F8 !important;
  box-shadow: 0px 12px 24px rgba(173, 173, 173, 0.08), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
  border-radius: 8px !important;
  padding: 18px;
}
.close_icon {
  /*position: absolute;*/
  /*right: 1rem;*/
  /*top: 0.8rem;*/
}
.close_icon_left {
  position: absolute;
  left: 0.2rem;
}
.v-alert {
  position: fixed;
  z-index: 1000;
  right: 3%;
  top: 5%;
}

.body-message {
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */


  /* Brown/500 */

  color: #514A41;
}

.snackbar--failed {
  background: #FF2E2E !important;
  color: white !important;

  .body-message {
    color: white !important;
  }
}

/*.mobile-snackbar {*/
/*  top: 3rem;*/
/*  left: 5% !important;*/
/*}*/
</style>
