<template>
  <section class="section">
    <div class="main">
      <div class="header">
        <h1>Brands & partners we're working with</h1>
      </div>
<!--      <v-row>-->
<!--        <v-col sm="12" md="3" cols="12">-->
<!--          <div class="column column_one">-->
<!--            <img src="../../assets/landingPage/codenonia.png" alt="codenonia">-->
<!--          </div>-->
<!--        </v-col>-->
<!--        <v-col sm="12" md="3" cols="12">-->
<!--          <div class="column column_one">-->
<!--            <img src="../../assets/landingPage/semicolon.png" alt="semicolon">-->
<!--          </div>-->
<!--        </v-col>-->
<!--        <v-col sm="12" md="3" cols="12">-->
<!--          <div class="column column_one">-->
<!--            <img src="../../assets/landingPage/gdg_cloud_lagos.png" alt="gdg_cloud_lagos">-->
<!--          </div>-->
<!--        </v-col>-->
<!--        <v-col sm="12" md="3" cols="12">-->
<!--          <div class="column column_one">-->
<!--            <img src="../../assets/landingPage/moda_a_.jpeg" alt="moda_analytica">-->
<!--          </div>-->
<!--        </v-col>-->
<!--      </v-row>-->
    </div>
  </section>
</template>

<script>
export default {
  name: "Partners",
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 1600px;
  margin: 0 auto 100px;
}

.main {
  width: 85%;
  margin: auto;

  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #322412;
    max-width: 700px;
    margin-bottom: 60px;
    @media (max-width: 767px) {
      font-size: 32px;
      line-height: 100%;
    }
  }

  span {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.01em;
    color: #999999;

  }
}

.imagePartners {
  background: url("../../assets/landingPage/partners.svg")
  center;
  height: 40px;
  margin-bottom: 100px;
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.column {
  position: relative;
  border-radius: 32px;
  height: 500px;

  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #10260b;
    position: absolute;
    bottom: 50px;
    left: 30px;
  }
}

.column_one {
  background: repeat center;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.column_two {
  background: url("../../assets/landingPage/gdg_cloud_lagos.png") repeat center;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.column_three {
  background: repeat center;
  height: 40px;
  width: 30px;
  margin-top: 50px;
  margin-bottom: 100px;
}

.column_four {
  background: repeat center;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 100px;
}
</style>