<template>
  <router-link to="/">
    <svg width="32" height="20" viewBox="0 0 32 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M26.034 6.98829C26.7667 8.47231 26.8151 10.5083 25.8199 12.3145L21.9368 19.4904H27.0368L29.9141 14.5969C31.655 11.4372 31.6439 7.76492 30.2263 4.89396C28.7763 1.95725 25.8106 -0.168376 21.9368 0.139501C19.9197 0.299808 17.828 0.635338 15.4424 1.1178L12.4059 1.59383L12.4054 1.5907L7.2191 2.43223L10.0923 14.7749H0.0600586V19.4832H15.6949L13.2908 6.4647L16.1636 5.78835L16.1616 5.77516C18.5386 5.28938 20.4903 4.97751 22.3054 4.83325C24.0584 4.69393 25.3337 5.57002 26.034 6.98829Z" fill="#FF7A01"/>
    </svg>
  </router-link>
</template>

<script>
export default {
  name: "LogoWithoutWord"
};
</script>

<style scoped>

</style>