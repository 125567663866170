import {confirmResetPassword, login, register, resetPassword} from "@/services/auth-services";

export default {
  async userLogin({ commit }, user) {
    return login(user).then(
      user => {
        commit("loginSuccess", user);

        return Promise.resolve(JSON.parse(user));
      },
      error => {
        commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },

  // async userLogout({ commit }) {
  //   logout();
  // },

  userRegister({ commit }, user) {
    console.log("hi", user);
    return register(user).then(
      response => {
        console.log(response);
        commit("registerSuccess");
        return Promise.resolve(response.data);
      },
      error => {
        commit("registerFailure");
        return Promise.reject(error);
      }
    );
  },

    async resetPassword({commit}, data){
        await resetPassword(data).then(res =>{
            console.log(res.data)
        })
    },

    async confirmResetPassword({commit}, data){
        await confirmResetPassword(data). then(res =>{
            console.log(res.data)
        })
            .catch(err => {
                console.log(err)
            })
    }
};
