export default {
    SET_SELECTED_COLOR: (state, color) => state.selectedColor = color,
    UPDATE_STATE_PROPERTY: (state, {prop, value}) => state[prop] = value,
    INSERT_NEW_SIZE: (state, size) => {
        const { sizes } = state
        console.log({size})
        if (sizes.map(size => +size).includes(size)) {
            state.fontSize = size
            return
        }
        console.log(Math.max(...sizes.filter(s => +size > +s)))
        let indexBeforeToOccupy = sizes.indexOf(Math.max(...sizes.filter(s => +size > +s)).toString())
        console.log({indexBeforeToOccupy})
        const displacedElements = sizes.splice(indexBeforeToOccupy++)
        console.log({displacedElements})
        console.log({indexBeforeToOccupy}, 'seco', {size})
        console.log(sizes[indexBeforeToOccupy])
        state.sizes[1] = "14"
        state.sizes[indexBeforeToOccupy] = size.toString()
        // console.log({displacedElements})
        // console.log({sizes})
        // let remainingSpaceToFill = sizes.length - indexBeforeToOccupy
        // console.log(remainingSpaceToFill)
        // let displacedElementIndex = 0
        // while (remainingSpaceToFill !== sizes.length) {
        //     state.sizes[remainingSpaceToFill++] = displacedElements[displacedElementIndex++]
        // }
        // state.fontSize = size
    },
    SET_BTN_IN_VIEW: (state, btn) => state.btnInView = btn,
    SET_EXPORT_CANVAS_DRAWER: (state, drawer) => state.exportCanvasDrawer = drawer,
    SET_DESIGN_TITLE: (state, title) => state.designTitle = title,
    SET_CANVAS_IMAGE_URL: (state, url) => state.canvasImageURL = url,
    SET_ALL_STATE:(state)=>{
        let all_state = state
        delete all_state.canvas
        delete all_state.sizes
        delete all_state.exportCanvasDrawer
        delete all_state.fontFamily
        delete all_state.fontLetterSpacing
        delete all_state.fontSize
        delete all_state.image
        delete all_state.letterAngle
        console.log(state)
        localStorage.setItem('allDesignProps',JSON.stringify(all_state))
    },
    SET_SELECTED_TEXT: (state, text) => state.selectedText = text,
    SET_BACK_SHIRT: (state, status) => state.isBack = status,
    SET_REQUEST_DESIGN: (state, design) => state.requestDesignDetails = design
}
