import axios from "axios";
import {logout} from "@/services/auth-services";

let AUTH_URL = 'https://kneenk-powerhouse.herokuapp.com';
// let BASE_URL = 'https://powerhouse-dev.herokuapp.com';

const UNAUTHORIZED_STATUS_CODE = 401
axios.interceptors.response.use((response) => Promise.resolve(response), (error) => {
  const status = error?.response?.status
  if (status === UNAUTHORIZED_STATUS_CODE) {
    const unauthorized = ['/', '/login']
    if (!unauthorized.includes(location.pathname)) {
      logout()
    }
  }
  return Promise.reject(error)
})

const authHeader = () => {
  let user = JSON.parse(localStorage.getItem('userToken'));

  if (user && user.access) {

    return { Authorization: `Bearer ${user.access}` };
  } else {
    return {};
  }

}

export const getProfileInfo = () => {
    return axios.get(
      AUTH_URL + "/api/user/profile/",
      { headers: authHeader() }
    )
}
export const editProfileInfo = (data) => {
    return axios.put(
      AUTH_URL + "/api/user/profile/update/", data,
      { headers: authHeader() }
    )
}
export const uploadToCloudinary = async data => {
  return axios.post('https://api.cloudinary.com/v1_1/spacehubpictures/auto/upload', data);
};
export const getAllUserOrders = () => {
  return axios.get(
    AUTH_URL + "/products/order/",
    { headers: authHeader() }
  )
}
export const getSingleOrderDetails = (order_id) => {
  return axios.get(
    AUTH_URL + `/products/order/${order_id}`,
    { headers: authHeader() }
  )
}
export const getAllSavedDesigns = () => {
  return axios.get(
    AUTH_URL + "/products/merch/",
    { headers: authHeader() }
  )
}
export const saveDesign = (data) => {
  return axios.post(
    AUTH_URL + "/products/merch/", data,
    { headers: authHeader() }
  )
}
export const deleteSavedDesign = (saved_design_id) => {
  return axios.delete(
    AUTH_URL + `/products/merch/${saved_design_id}/`,
    { headers: authHeader() }
  )
}
