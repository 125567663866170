import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import vuetify from './plugins/vuetify'
import VueFeather from 'vue-feather';
import 'animate.css'
import '@/assets/styles/global.scss'
import {extend, localize, ValidationObserver, ValidationProvider} from 'vee-validate';
import en from 'vee-validate/dist/locale/en.json';
import * as rules from 'vee-validate/dist/rules';
import axios from "axios";
import displaySnackbar from "@/utils/snackbar"
import VueDayjs from 'vue-dayjs'
import Numeral from 'numeral';
import injectAxios from "@/plugins/axios";
import getUserData, { updateUserData } from "@/plugins/userData";
import VuePhoneNumberInput from 'vue-phone-number-input';
import 'vue-phone-number-input/dist/vue-phone-number-input.css';
// eslint-disable-next-line no-unused-vars

//    "eslint-plugin-prettier": "^3.4.0",

Vue.prototype.$displaySnackbar = displaySnackbar
Vue.prototype.$getUserData = getUserData()
Vue.prototype.$updateUserData = updateUserData

injectAxios()

Vue.mixin({
    computed: {
        isLoggedIn() {
            return !!JSON.parse(localStorage.getItem("userToken"))
        }
    }
})


axios.interceptors.request.use(
    request => {

        if (!navigator.onLine) {
            store.dispatch("snackBar/displaySnackBar", {
                success: false,
                snackBarMessage: "Kindly check your internet",
            })
        }
        return request;
    },
    error => {

        return Promise.reject(error)
    }
)

//vee-validate form validation
Object.keys(rules).forEach(rule => {
    extend(rule, rules[rule]);
});
extend('http', {
    validate: (value) => {
        return !value.includes("http") && !value.includes("//")
    },
    message: "Kindly remove {_value_} from your website link, use www.mywebsite.com only"
});

extend('createpassword', {
    params: ['target'],
    validate(value, {target}) {
        return value === target;
    },
    message: 'Password confirmation does not match'
});
Vue.config.productionTip = false
localize("en", en);

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('vue-feather', VueFeather);
Vue.component('vue-phone-number-input', VuePhoneNumberInput);

Vue.use(VueDayjs)

Vue.prototype.$numeral = Numeral

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')

