import kneenk_black from "@/assets/shirts/shirts-women/k-black-woman-front.jpg";
import kneenk_white from "@/assets/shirts/shirts-women/k-white-woman-front.jpg";
import kneenk_red from "@/assets/shirts/shirts-women/k-red-woman-front.jpg";
import kneenk_yellow from "@/assets/shirts/shirts-women/k-yellow-woman-front.jpg";
import kneenk_peach_pink from "@/assets/shirts/shirts-women/k-pink-woman-front.jpg";
import kneenk_green from "@/assets/shirts/shirts-women/k-green-woman-front.jpg";
import kneenk_green_back from "@/assets/shirts/shirts-women/k-green-woman-back.jpg";
import kneenk_orange from "@/assets/shirts/shirts-women/kneenk-front-web.jpg";
import kneenk_ash from "@/assets/shirts/shirts-women/k-ash-woman-front.jpg";
import kneenk_back_black from "@/assets/shirts/shirts-women/k-black-woman-back.jpg";
import kneenk_blue from '@/assets/shirts/shirts-women/k-blue-woman-front.jpg'
import kneenk_sky_blue from '@/assets/shirts/shirts-women/k-skyblue-woman-front.jpg'
import kneenk_grey from '@/assets/shirts/shirts-women/k-grey-woman-front.jpg'
import kneenk_gray from '@/assets/shirts/kneek-gray.svg'
import kneenk_white_back from '@/assets/shirts/shirts-women/k-white-woman-black.jpg'
import kneenk_red_back from '@/assets/shirts/shirts-women/k-red-woman-back.jpg'
import kneenk_yellow_back from '@/assets/shirts/shirts-women/k-yellow-woman-back.jpg'
import kneenk_orange_back from '@/assets/shirts/shirts-women/k-orange-back-woman.jpg'
import kneenk_peach_pink_back from '@/assets/shirts/shirts-women/k-pink-woman-back.jpg'
import kneenk_ash_back from '@/assets/shirts/shirts-women/k-ash-woman-back.jpg'
import kneenk_blue_back from '@/assets/shirts/shirts-women/k-blue-woman-back.jpg'
import kneenk_grey_back from '@/assets/shirts/shirts-women/k-grey-woman-back.jpg'
import kneenk_sky_blue_back from '@/assets/shirts/shirts-women/k-skyblue-woman-back.jpg'

export default {
    selectedShirt (state) {
        // let shirts={}

        return getFrontImage(state.selectedColor)
    },
    selectedBackShirt (state) {
        // let shirts={}
        return getBackImage(state.selectedColor)


        // return back[state.selectedColor] ?? kneenk_back_black
    },
}

export const getFrontImage = (color) => {
    console.log(color)
    const shirts = {
        '#000': kneenk_black,
        '#FFF': kneenk_white,
        '#E80000': kneenk_red,
        '#FFD706': kneenk_yellow,
        '#CE08BA': kneenk_peach_pink,
        '#23B100': kneenk_green,
        '#FF7A01': kneenk_orange,
        '#A4A4A4': kneenk_ash,
        '#2250C7': kneenk_blue,
        '#48A7FF': kneenk_sky_blue,
        '#E7E7E7': kneenk_grey
    }
    return shirts[color] ?? kneenk_white
}

export const getBackImage = (color) => {
    const shirts = {
        '#000': kneenk_back_black,
        '#FFF': kneenk_white_back,
        '#E80000': kneenk_red_back,
        '#FFD706': kneenk_yellow_back,
        '#CE08BA': kneenk_peach_pink_back,
        '#23B100': kneenk_green_back,
        '#FF7A01': kneenk_orange_back,
        '#A4A4A4': kneenk_ash_back,
        '#2250C7': kneenk_blue_back,
        '#48A7FF': kneenk_sky_blue_back,
        '#E7E7E7': kneenk_grey_back
    }
    return shirts[color] ?? kneenk_back_black
}
