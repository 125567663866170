<template>
  <section class="section" >
    <div class="footer" >
      <v-row >
        <v-col md="6" cols="12" class="column">
          <div style="flex-direction: row; display: flex; align-items: center">
            <img src="@/assets/footer_logo.svg" height="20px" width="117px" class="v-img" alt="logo" />
          </div>
          <p class="copy mt-8">
          kneenk &copy; {{currentYear}} All Rights Reserved.
          <!-- Built with &nbsp; <span style="color: red">❤</span> &nbsp;in Lagos, Nigeria -->
        </p>
        </v-col>
        <v-col md="6" cols="12" class="colTwo" >
          <v-row>
            <v-col sm="6" cols="12" class="column">
                  <h3 >Features</h3>
              <div @click="$router.push({name: 'Build'})" class="mb-4">
              <h5>Create Merch</h5>
              </div>
              <div class="tw-flex tw-items-center mb-4">
                <h5>Sell as a Creator</h5>
                <span class="ml-2 tw-p-1">COMING SOON</span>
              </div>
              <div class="tw-flex tw-items-center mb-4">
                <h5>Start a Fundraise</h5>
                <span class="ml-2 tw-p-1">COMING SOON</span>
              </div>

            </v-col>
            <v-col sm="6" cols="12" class="column">
              <h3>Get in touch</h3>
                <div style="flex-direction: row; display: flex; align-items: center">
            <a href="https://www.instagram.com/kneenk_/" target="_blank">
              <img src="@/assets/ig.svg" height="20px" width="32px" class="v-img" alt="logo" style="margin-right: 1vw" />
            </a>
            <img src="@/assets/tw.svg" height="20px" width="32px" class="v-img" alt="logo" />
          </div>
              <h3></h3>
              <a style="color: white" href="mailto: hello@kneenk.com">hello@kneenk.com</a>
            </v-col>
          </v-row>
            
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "LandingPageFooter",
  computed:{
    currentYear(){
      const date = new Date()
      return date.getFullYear()
    }
  },
}
</script>

<style lang="scss" scoped>
.section{
background: #1D2025;

}
.footer{
  max-width: 1600px;
  margin: auto;
  width: 85%;
  padding: 100px 0;
}
.column{
// height: 380px;
display: flex;
flex-direction: column;
justify-content: flex-start;
span{
  background: rgba(255, 255, 255, 0.1);
border-radius: 6px;
  font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 120%;
letter-spacing: 0.12em;
color: #FCFBFA;
}
}
.copy{
  font-family: DM Sans, serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 18px;
  color: #FFFFFF;
}
.colTwo{
  h3{
    font-style: normal;
font-weight: 700;
font-size: 14px;
line-height: 18px;
color: #FFFFFF;
margin-bottom: 20px;
  }
  h5{
      font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 18px;
color: #FFFFFF;
cursor: pointer;

  }
}
</style>