<template>
      <v-app>
        <new-landing-page-header v-if="$route.name==='Home' || $route.name==='Categories' || $route.name==='Event' || $route.name==='PaymentReceivedPage'"/>
        <snack-bar
            :success="success"
            :show-snack-bar="showSnackBar"
            :snack-bar-hint="snackBarHint"
            :snack-bar-message="snackBarMessage"/>
        <v-main>
          <router-view/>
        </v-main>
          <div v-if="$route.name==='Categories'" class="tw-mt-80">
              <start-creating-section />
          </div>
        <div v-if="$route.name==='Home' || $route.name==='Categories' || $route.name==='Event'">
          <LandingPageFooter/>
        </div>
      </v-app>
</template>

<script>
if(window.location.protocol !== 'https:') {
  if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
  }
  else {
    location.href = location.href.replace("http://", "https://");
  }
}
import './index.css'
import LandingPageFooter from "@/components/LandingPageFooter";
import SnackBar from "@/ui/snackBar/SnackBar";
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import {mapGetters} from "vuex";
import NewLandingPageHeader from './components/NewLandingPageHeader.vue';
import StartCreatingSection from "@/components/LandingPage/StartCreatingSection.vue";
export default {
  name: 'App',
  components: {StartCreatingSection, SnackBar, LandingPageFooter,NewLandingPageHeader},
  mixins: [isUserLoggedIn],
  data: () => ({

  }),
  computed:{
    ...mapGetters("snackBar", ["snackBarMessage", "success", "showSnackBar", "snackBarHint"]),
  }
};
</script>

<style scoped lang="scss">
* {
  font-family: 'DM Sans', sans-serif !important;
  scroll-behavior: smooth !important;
  margin: 0px;
  padding: 0px;
}
.v-toolbar__content, .v-toolbar__extension{
  padding: 4px 8px;
}
.v-sheet.v-card:not(.v-sheet--outlined){
  box-shadow: none;
  padding: 0px;
}
.v-application .my-4 {
  margin: 0px;
}
.v-application{
  font-family: 'DM Sans', sans-serif;
  color: #292929;
}

.label{
  line-height: 18px;
  font-weight: 400;
  color: black;
}

.v-text-field--outlined fieldset {
  border-color: #BAC1C7 !important;

}

.v-text-field--outlined.v-input--is-focused fieldset,
.v-text-field--outlined.v-input--has-state fieldset {
  border-color: rgba(0, 0, 0, 0.12) !important;
}

::v-deep .theme--light.v-text-field--outlined:not(.v-input--is-focused):not(.v-input--has-state) > .v-input__control > .v-input__slot fieldset {
  border: 1px solid #EAEAEA;
}

::v-deep .v-window__prev {
  background: none;
}

::v-deep .v-window__next{
  background: none;
}
#color{
  border-radius: 50% !important;
  height: 60px !important;
  width: 60px !important;
  border: none !important;
  outline: none !important;
  -webkit-appearance: none !important;
}

#color::-webkit-color-swatch-wrapper {
  padding: 0 !important;
}
#color::-webkit-color-swatch {
  border: none !important;
  border-radius: 50% !important;
}
</style>
