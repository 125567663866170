<template>
  <div class="section">
    <div class="main">
      <h2>What our customers are saying about us</h2>
      <v-row>
        <v-col sm="12" md="6" cols="12">
          <div class="columnOne">
            <div class="auth-form-image-nav">
              <div v-for="item in testimonials" :class="item.navClass" :key="item.name"></div>
            </div>
            <div v-if="activeTestimonial.text">
              <div :class="animationClass">
                <div class="content">
                  <h4>{{ activeTestimonial.text }}</h4>
                  <h3 >
                    {{ activeTestimonial.name }}
                  </h3>
                </div>
              </div>
            </div>
          </div>
        </v-col>
        <v-col sm="12" md="6" cols="12">
          <div class="imageDiv">
            <img v-if="activeTestimonial.image" :src="activeTestimonial.image" :alt="activeTestimonial.text" :class="animationClass ? 'animate__animated animate__fadeIn' : ''" /> 
          </div>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {teesOne, teesTwo, teesThree} from "@/assets/cloudinary";

export default {
  data() {
    return {
      testimonials: [
        {
          text: "I was initially skeptical about contacting you for the job because your brand is pretty new… but you guys did a great job. I particularly liked the texture of the T-shirt. My expectations were actually exceeded!",
          image: teesOne,
          name: "Temilade",
		      navClass: "inactive-nav"
        },
        {
          text: "Everything was great! I only came up with what I wanted on the shirt and I expected it to be ordinary, but I was so excited when I saw the design. I love everything about the shirt (it’s great quality).\n" +
              "Would definitely be getting more from this brand!",
          image: teesTwo,
          name: "Seun",
		      navClass: "inactive-nav"
        },
        {
          text: "My sister referred me to you guys because she got two Tees from you and they were really lovely. I ordered this one for a friend and she liked it.",
          image: teesThree,
          name: "Moyosore",
		      navClass: "inactive-nav"
        },
      ],
      activeTestimonial: {},
      animationClass: '',
      slideInterval: 5000
    };
  },
  mounted() {
    let index = 1;

		this.testimonials[0].navClass = 'active-nav';
    this.activeTestimonial = this.testimonials[0];
		
		setInterval(() => {
			this.testimonials.forEach(row => row.navClass = 'inactive-nav');
			this.testimonials[index].navClass = 'active-nav';
      index++;
      index = index >= this.testimonials.length ? 0 : index;
      this.activeTestimonial = this.testimonials[index];
      this.animationClass = 'animate__animated animate__slideInRight';
      setTimeout(() => this.animationClass = '', this.slideInterval / 2);

		}, this.slideInterval);
  }
}

</script>

<style  scoped>
	.active-nav {
		left: 0%;
      background-color: #00050D !important;
      width: 120px !important;
	}

	.inactive-nav {
		left: 0%;
      background-color: #E1D3C2;
      width: 20px;
	}

.section {
  max-width: 1600px;
  margin: 100px auto;
}
.main {
  width: 85%;
  margin: auto;
}
main h2{
  font-style: normal;
  font-weight: 700;
  font-size: 40px;
  line-height: 100%;
  letter-spacing: -0.04em;
  color: #322412;
  max-width: 416px;
  margin-bottom: 50px;

}
.columnOne {
  padding: 56px;
  width: 100%;
  height: 360px;
  background: #f9f4ee;
  border-radius: 32px;
  overflow: hidden;
}


.auth-form-image-nav {
  left: 0;
  bottom: 0;
  width: 100%;
  height: 30px;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
  padding-left: 20px;
  padding-top: 5px;
}


.auth-form-image-nav > div {
  width: 20px;
  border-radius: 3.5px;
  height: 5px;
  margin-right: 5px;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px !important;
}
.content h3{
  font-style: normal;
  font-weight: 700;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: -0.01em;
  color: #10260B;
  padding-top: 50px

}
.content h4{
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 160%;
  letter-spacing: -0.01em;
  color: #322412;

}
.imageDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 360px;
}
@media (max-width: 500px) {
  .columnOne{
      height: fit-content;
      padding: 25px
  }
  
}
</style>
