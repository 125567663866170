<template>
<section class="section">
  <div class="main">
    <div class="banner_container">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
          class="tw-flex tw-items-center column_one tw-justify-center"
        >
          <div class="tw-p-5" style="max-width: 550px !important;">
            <h1>Select, customize, place an order</h1>
                        <h5>
                          Design custom tees, hoodies, or wristbands and we’d deliver them to you in no time
                        </h5>
            <div @click="$router.push('/profile')" class="tw-flex mobile_try_merch tw-cursor-pointer tw-my-5 md:tw-hidden tw-items-center">
                <span>Go To Profile</span>
                <v-icon size="18" color="#514A41" class="tw-pl-1"
                  >mdi-arrow-top-right</v-icon
                >
              </div>
            <div class="tw-flex tw-items-center tw-mt-4">
              <v-btn to="/build" text class="button"> {{getLogInStatus? 'Create Merch' : 'Get Started'}} </v-btn>
              <div class="tw-hidden tw-cursor-pointer tw-pl-5 md:tw-flex tw-items-center" v-if="!getLogInStatus">
                <v-btn rounded text to="/signup" class="try-merch">
                    Create an account
                  <v-icon size="18" color="#514A41" class="tw-pl-1"
                  >mdi-arrow-top-right</v-icon>
                </v-btn>
              </div>
              <div class="tw-hidden tw-cursor-pointer tw-pl-5 md:tw-flex tw-items-center" v-else>
                <v-btn rounded text to="/profile" class="try-merch">
                    Go To Profile
                  <v-icon size="18" color="#514A41" class="tw-pl-1"
                  >mdi-arrow-top-right</v-icon>
                </v-btn>
              </div>
            </div>
          </div>
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="tw-flex tw-items-center column_two tw-justify-center"
        >
          <img :src="images.womanImgTwo" alt="" />
        </v-col>
      </v-row>
      <div class="wavy_line">
        <img :src="images.wavyLine" alt="" />
      </div>
    </div>
  </div>
  </section>
</template>

<script>
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import {womanImgTwo, wavyLine} from "@/assets/cloudinary";

export default {
  name:"Banner",
  mixins: [isUserLoggedIn],
  data(){
    return{
      images: {
        womanImgTwo, wavyLine
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.section{
    max-width: 1600px;
  margin: auto;
}
.main {
  margin: 100px 0 ;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #322412;
    @media (max-width: 767px) {
      font-size: 38px;
      padding-top: 32px;
    }
  }
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #322412;
    max-width: 316.67px;
    padding: 16px 0;
    @media (max-width: 767px) {
    max-width: none;
    }
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #322412;

    &:hover, &:active{
      color: #ff7a01;
    }
  }
}
.banner_container {
  width: 85%;
  background: #f9f4ee;
  border-radius: 64px;
  margin: auto;
  position: relative;
}
.button {
  width: 167px;
  height: 54px !important;
  background: #ff7a01;
  font-weight: bold;
  font-size: 18px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0;
  text-transform: capitalize;
  color: white;
  background: #ff7a01;
  box-shadow: 0px 24px 40px #e5ceb3;
  border-radius: 50px;
}
.column_two {
  img {
    margin-top: -50px;
  @media (max-width: 959px) {
    margin-top: 0;
  }
  }
}
.column_one div {
  max-width: 400px;
  @media (max-width: 767px) {
    max-width: none;
    flex-direction: column;
    text-align: center;

  }
}
.wavy_line {
  position: absolute;
  top: 50%;
  left: -25px;
}
.mobile_try_merch{
  flex-direction: initial !important;
  justify-content: center;
  cursor: pointer;
}
.try-merch{
  font-size: 16px;
  text-transform: capitalize
}
</style>