import axios from "axios";

let AUTH_URL = 'https://kneenk-powerhouse.herokuapp.com';
let BASE_URL = 'https://powerhouse-dev.herokuapp.com';

const authHeader = () => {
  let user = JSON.parse(localStorage.getItem('userToken'));
  console.log({user})

  if (user && user.access) {

    return { Authorization: `Bearer ${user.access}` };
  } else {
    return {};
  }

}

// if (process.env.NODE_ENV === "development") {
//   AUTH_URL = "";
//   BASE_URL = "";
// } else {
//   AUTH_URL = process.env.VUE_APP_AUTH_URL;
//   BASE_URL = process.env.VUE_APP_BASE_URL;
// }

export const login = async user => {
  return await axios
    .post(AUTH_URL + "/api/auth/jwt/login/", {
      email: user.email,
      password: user.password
    })
    .then(response => {
      const base64Url = response.data.access.split(".")[1];
      const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      const jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      if (response) {
        localStorage.setItem("userToken", JSON.stringify(response.data));
      }
      return jsonPayload;
    });
};

export const logout = () => {
  localStorage.clear();
  window.location.href = "/login";
};

export const register = user => {
  return axios.post(AUTH_URL + "/api/auth/users/", user);
};

export const verifyEmail = data => {
  return axios.post(AUTH_URL + "/auth/user/verify_registration/", data);
};
export const resetPassword = email => {
  return axios.post(
    AUTH_URL + `/api/auth/confirm_reset_password/`,
    email
  );
};
export const confirmResetPassword = data => {
  return axios.put(
    AUTH_URL + "/api/auth/confirm_reset_password/",
    data
  );
};
export const changePassword = data => {
  let userData = JSON.parse(localStorage.getItem('userData'))
  return axios.patch(
    AUTH_URL + `/api/auth/change_password/${userData.id}/`,
    data,{headers:authHeader()}
  );
};

export const verifyFlutterWavePayment=(data)=>{
  return axios.post(
      BASE_URL+"/payment/payment/verify_flutter_wave_transaction/",data,{headers:authHeader()}
  )
}



export const removeFromCart = (data) => {
  return axios.put(
    BASE_URL + "/payment/cart/remove_courses_from_cart/", data,
    { headers: authHeader() }
  )
}

export const changePasswordLoggedIn = (id, data) => {
  return axios.put(
    AUTH_URL + `/auth/update-password/${id}/`,
    data, { headers: authHeader() }
  );
};
export const addMerchToCart = (data) => {
  return axios.post(
    AUTH_URL + "/products/merch/add_merch_to_cart/", data,
    { headers: authHeader() }
  )
}
export const getItemInCart = () => {
  return axios.get(
    AUTH_URL + "/products/cart/",
    { headers: authHeader() }
  )
}
export const removeItemInCart = (data) => {
  return axios.delete(
      AUTH_URL + `/products/cart/${data.id}`,
      { headers: authHeader() }
  )
}
export const verifyPayment = (data) => {
  return axios.post(
    AUTH_URL + "/payments/payment/verify/", data,
    { headers: authHeader() }
  )
}
export const getShippingAddress = () => {
  return axios.get(
    AUTH_URL + "/products/shipping/",
    { headers: authHeader() }
  )
}
export const getEventDetail = (code) => {
  return axios.get(
    AUTH_URL + `/events/event/event_detail/?code=${code}`,
    { headers: authHeader() }
  )
}
export const verifyEventPayment = (data) => {
  return axios.post(
    AUTH_URL + "/events/attend/verify/", data,
    { headers: authHeader() }
  )
}
