import Vue from "vue";
import Vuex from 'vuex'
import snackBar from './snackBar'
import onboarding from './onboarding'
import buildMerch from '@/store/buildMerch'
import profile from '@/store/profile'

Vue.use(Vuex)
export default new Vuex.Store(
    {
        modules:{
            snackBar,
            onboarding,
            buildMerch,
            profile
        }
    }
);