<template>
  <section class="section">
    <div class="main">
      <div class="tw-flex tw-justify-between tw-items-center innerMain">
        <div class="tw-flex tw-justify-center tw-items-center web">
          <Logo
            @click="$router.push('/')"
            class="tw-hidden md:tw-block mr-7"
          />
          <div @click="$route.name!=='Home'? $router.push('/'): ''">
          <LogoWithoutWord class="md:tw-hidden" />
          </div>
          <div class="tw-hidden md:tw-flex tw-justify-center tw-items-center">
            <v-btn to="/categories" class="headerP mx-5" rounded text>
              All Products
            </v-btn>
<!--            <h5 ></h5>-->
            <!-- <h5 class="header mx-7">Features</h5> -->

            <v-menu bottom offset-y right class="menu">
              <template #activator="{ on, attrs }">
                <div v-bind="attrs" icon v-on="on" class="tw-flex tw-items-center">

<!--                  <h5 class="header ml-3">Features</h5><v-icon color="black">mdi-chevron-down</v-icon>-->
                  <v-btn class="headerP ml-3" rounded text>
                    Features
                    <v-icon color="black">mdi-chevron-down</v-icon>
                  </v-btn>
                </div>
              </template>
              <v-list class="features px-4 py-5">
                <v-list-item class=" tw-cursor-pointer" @click="$router.push('/build')">
                  <v-list-item-title>
                    <div class="tw-flex py-2 tw-items-center">
                        <img src="@/assets/landingPage/build_merch_feature_icon.svg" alt="">
                        <div class="tw-pl-3">
                          <h4>Build Merch</h4>
                          <h6>Customise merch in a few clicks</h6>
                        </div>
                      </div>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item class=" tw-cursor-pointer">
                    <v-list-item-title>
                      <div class="tw-flex py-2 tw-items-center">
                        <img src="@/assets/landingPage/fund_raise_feature_icon.svg" alt="">
                        <div class="tw-pl-3">
                          <div class="tw-flex tw-items-center">
                          <h4>Fund-raise</h4>
                          <div class="coming_soon ml-1 tw-p-1">COMING SOON</div>
                          </div>
                          <h6>Design and fund-raise with custom merch <br>designs in few clicks</h6>
                        </div>
                      </div>
                    </v-list-item-title>
                </v-list-item>
                <v-list-item class=" tw-cursor-pointer">
                    <v-list-item-title>
                      <div class="tw-flex py-2 tw-items-center">
                        <img src="@/assets/landingPage/creator_feature_icon.svg" alt="">
                        <div class="tw-pl-3">
                          <div class="tw-flex tw-items-center">
                          <h4>Sell as a creator</h4>
                          <div class="coming_soon ml-1 tw-py-1">COMING SOON</div>
                          </div>

                          <h6>Sell merch you customize easily and quickly</h6>
                        </div>
                      </div>
                    </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <!-- <h5 @click="$route.name!=='Event'? $router.push('/events/sc-cohort-8') : ''" class="header mx-5">Events</h5> -->
          </div>
        </div>
        <div class="tw-flex tw-items-center">
          <div v-if="!getLogInStatus" class="tw-flex tw-items-center">
            <div class="tw-hidden md:tw-flex">
              <v-btn class="login" rounded text to="/login">
                <v-icon class="mr-1" color="black">mdi-account-outline</v-icon>
                Login
              </v-btn>
            </div>
            <v-btn
              v-if="!dropdownMenuIsVisible"
              text
              rounded
              to="/build"
              class="get_started"
              >Get Started</v-btn
            >
          </div>
          <div v-if="getLogInStatus" class="d-flex tw-items-center tw-justify-center tw-flex-row">
            <v-menu offset-y class="logged-in">
              <template v-slot:activator="{ on, attrs }">
                <div
                  v-bind="attrs"
                  v-on="on"
                  class="d-flex tw-items-center tw-justify-center ml-4"
                >
                  <v-img
                    class="mr-1"
                    width="25"
                    height="25"
                    style="border-radius: 50%"
                    :src="profileImage"
                  >
                  </v-img>
                  <span>{{ userProfile.first_name }}</span>
                </div>
              </template>
              <v-list>
                <v-list-item to="/profile">
                  <v-list-item-title class="tw-flex tw-items-center mr-6">
                    <v-icon class="mr-1" color="#AAAAAA"
                      >mdi-account-outline</v-icon
                    >
                    <span> Profile</span>
                  </v-list-item-title>
                </v-list-item>
                <v-list-item @click="userLogout()">
                  <v-list-item-title class="tw-flex tw-items-center mr-6">
                    <img class="mr-2" src="@/assets/logout.svg" alt="" />
                    <span> Logout</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

              <div style="width: 100%" class="tw-pl-3" >
                  <v-btn to="/build" block text rounded class="get_started"
                  >Create Merch</v-btn
                  >
              </div>
          </div>
          <div
            class="header_container_mobile_nav ml-3 md:tw-hidden tw-flex"
            @click="dropdownMenuIsVisible = !dropdownMenuIsVisible"
          >
            <img
              class="mr-2"
              src="@/assets/menu_icon.svg"
              alt=""
              v-if="!dropdownMenuIsVisible"
            />

            <v-icon v-if="dropdownMenuIsVisible">mdi-close</v-icon>
          </div>
          <div
            :class="dropdownMenuIsVisible ? 'tw-flex' : 'tw-hidden'"
            class="block md:tw-hidden header_container_mobile_menu"
          >
            <div class="tw-flex p-4 tw-flex-col tw-justify-between tw-w-full features">
              <div class="">
                <h5 @click="$route.name!=='Categories'? $router.push('/categories'): '',dropdownMenuIsVisible = !dropdownMenuIsVisible" class="header">All Products</h5>
                <!-- <h5 class="header mb-10">Features</h5> -->

                <v-expansion-panels>
                  <v-expansion-panel v-for="(item, i) in 1" :key="i">
                    <v-expansion-panel-header> Features  </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <div class="tw-flex py-2 tw-items-center" @click="$router.push('/build')">
                        <img src="@/assets/landingPage/build_merch_feature_icon.svg" alt="">
                        <div class="tw-pl-3">
                          <h4>Build Merch</h4>
                          <h6>Customise merch in a few clicks</h6>
                        </div>
                      </div>
                      <div class="tw-flex py-2 tw-items-center">
                        <img src="@/assets/landingPage/fund_raise_feature_icon.svg" alt="">
                        <div class="tw-pl-3">
                          <div class="tw-flex tw-items-center">
                          <h4>Fund-raise</h4>
                          <div class="coming_soon ml-1 tw-p-1">COMING SOON</div>
                          </div>
                          <h6>Design and fund-raise with custom merch designs in few clicks</h6>
                        </div>
                      </div>
                      <div class="tw-flex py-2 tw-items-center">
                        <img src="@/assets/landingPage/creator_feature_icon.svg" alt="">
                        <div class="tw-pl-3">
                          <div class="tw-flex tw-items-center">
                          <h4>Sell as a creator</h4>
                          <div class="coming_soon ml-1 tw-py-1">COMING SOON</div>
                          </div>

                          <h6>Sell merch you customize easily and quickly</h6>
                        </div>
                      </div>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <!-- <h5 @click="$route.name!=='Event'? $router.push('/events/sc-cohort-8'): '',dropdownMenuIsVisible = !dropdownMenuIsVisible" class="header mb-5">Events</h5> -->
              </div>

              <div class="tw-flex tw-flex-col tw-items-center">
                <div
                  v-if="!getLogInStatus"
                  class="md:tw-hidden tw-flex tw-mb-5"
                >
                  <v-btn class="login" rounded text to="/login">
                    <v-icon class="mr-1" color="black"
                      >mdi-account-outline</v-icon
                    >
                    Login
                  </v-btn>
                </div>
                <div style="width: 100%" class="mb-6">
                  <v-btn to="/build" block text rounded class="get_started"
                    >Get Started</v-btn
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import Logo from "@/reuseable/Logo";
import LogoWithoutWord from "@/reuseable/LogoWithoutWord";
import isUserLoggedIn from "@/mixins/isUserLoggedIn";
import { mapGetters } from "vuex";
import { logout } from "@/services/auth-services";

export default {
  name: "NewLandingPageHeader",
  components: { LogoWithoutWord, Logo },
  mixins: [isUserLoggedIn],
  data() {
    return {
      dropdownMenuIsVisible: false,
      isActive: false
    };
  },
  computed: {
    userProfile() {
      return JSON.parse(localStorage.getItem("userData"));
    },
    ...mapGetters("profile", ["profileInfo"]),
    profileImage(){
      console.log(this.$getUserData.fromLocalStorage())
      return this.$getUserData.fromLocalStorage()?.image || 'https://res.cloudinary.com/spacehubpictures/image/upload/v1657317163/kneek/kqjxlana8u4kaikstsrw.png'
    }
  },
  methods: {
    userLogout() {
      logout();
    },
  },
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 1600px;
  margin-bottom: 80px;
}
.main {
  background-color: #ffffff;
  border-bottom: 1px solid #f9f4ee;
  padding: 24px 0;
  margin: auto;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
}
.innerMain {
  width: 85%;
  margin: auto;
}

.login ::v-deep .v-btn__content {
  font-weight: 500;
  font-size: 15px;
  line-height: 20px;
  color: #292929;
  text-transform: capitalize;
}
.get_started {
  padding: 16px 16px;
  min-width: 128px;
  width: auto;
  height: 34px;
  background: #ff7a01;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.02em;
  color: white;
  text-transform: capitalize;
  border-radius: 50px;
}
.header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
  color: #322412;
  cursor: pointer;

  &:hover, &:active{
    color: #ff7a01;
  }
}
.headerP ::v-deep .v-btn__content{
  text-transform: capitalize;
  color: #322412;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 120%;
  letter-spacing: -0.01em;
}
.header_container_mobile_menu {
  position: fixed;
  top: 72.5px;
  right: 0;
  z-index: 9999;
  width: 100%;
  height: 92%;
  background-color: #fff;
  padding: 30px 20px;
  overflow: auto;
}
.header_container_mobile_menu ::v-deep .v-expansion-panels{
    justify-content: flex-start;
    width: fit-content;
}
.header_container_mobile_menu ::v-deep .v-expansion-panel::before{
    box-shadow: none;
}
.header_container_mobile_menu ::v-deep .v-expansion-panel-header{
  padding: 0;
  width: fit-content;
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 120%;
letter-spacing: -0.01em;
color: #322412;

}
.header_container_mobile_menu ::v-deep .v-expansion-panel-content__wrap{
  padding: 0 0 8px 16px;
}
.features {
h6{
  font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 14px;
line-height: 120%;
letter-spacing: -0.01em;
color: #95908A;
}
h4{
  font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 120%;
letter-spacing: -0.01em;

color: #322412;

}
}
.coming_soon{
background: #F9F4EE;
border-radius: 6px;
font-style: normal;
font-weight: 700;
font-size: 10px;
line-height: 120%;
letter-spacing: 0.12em;
justify-content: center;
color: #514A41;
}
.v-menu__content{
  box-shadow: 0px 12px 24px rgba(113, 104, 104, 0.24), 0px 2px 4px rgba(173, 173, 173, 0.04) !important;
border-radius: 8px !important;
}
</style>
