import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/signup',
        name: 'Signup',
        component: () => import('../views/Signup'),
        meta: {
            noAuth: true
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('../views/Login'),
        meta: {
            noAuth: true
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('../views/ResetPassword')
    },
    {
        path: '/confirm-password',
        name: 'ConfirmPassword',
        component: () => import('../views/ConfirmPassword')
    },
    {
        path: '/verify',
        name: 'VerifyEmailPage',
        component: () => import('../views/ConfirmEmail')
    },
    {
        path: '/build',
        name: 'Build',
        component: () => import('../views/newBuild')
    },
    {
        path: '/shipping',
        name: 'Shipping',
        component: () => import('../views/Shipping')
    },
    {
        path: '/add-to-cart',
        name: 'AddToCart',
        component: () => import('@/views/AddToCart')
    },
    {
        path: '/cart',
        name: 'Cart',
        component: () => import('@/views/Cart')
    },
    {
        path: '/profile',
        name: 'ProfilePage',
        component: () => import('@/views/Profile')
    },
    {
        path: '/payment',
        name: 'PaymentNotification',
        component: () => import('@/views/Payment')
    },
    {
        path: '/order-details/:orderId',
        name: 'OrderDetailsPage',
        component: () => import('@/components/profile/SavedOrderDetails')
    },
    // {
    //     path: '/play',
    //     name: 'PlayStation',
    //     component: () => import('@/views/newBuild')
    // }
    {
        path: '/categories',
        name: 'Categories',
        component: () => import('@/views/Categories')
    },
    {
        path: '/campaign/sc-cohort-8',
        name: 'Event',
        component: () => import('@/views/Event')
    },
    {
        path: '/payment-successful',
        name: 'PaymentReceivedPage',
        component: () => import('@/views/PaymentReceivedPage')
    },
    {
        path: '/campaign/:id',
        name: 'CampaignDetails',
        component: () => import('@/views/CampaignDetails')
    },
    {
        path: '/create-campaign',
        name: 'CreateCampaign',
        component: () => import('@/components/campaign/CreateCampaign')
    },
    {
        path: '/campaign-created',
        name: 'CampaignSplash',
        component: () => import('@/components/profile/campaigns/SplashScreen')
    }
]

const router = new VueRouter({
    mode: 'history',
    routes
})

// router.beforeEach((to, from, next) => {
//     if (to.name === 'Home') {
//         return next()
//     }
//     const isUserLogIn = JSON.parse(sessionStorage.getItem('userData'))
//     const routeNotProtected = to.matched.some(record => record.meta.noAuth);
//     if (!isUserLogIn && !routeNotProtected) next('/login')
//     else next()
// })

export default router
