<template>
  <kneek-landing-page/>
</template>

<script>
  import KneekLandingPage from "@/components/KneekLandingPage";
  import { getProfileInfo } from "@/services/user-profile";

  export default {
    name: 'Home',

    components: {
      KneekLandingPage
    },
    async created () {
      const { data } = await getProfileInfo()
      this.$updateUserData(data)
      // let sessionData = localStorage.getItem('userData')
      // if (sessionData) {
      //   sessionData = JSON.parse(sessionData)
      //
      //   const userData = {
      //     ...sessionData,
      //     ...data
      //   }
      //   localStorage.setItem('userData', JSON.stringify(userData))
      // }
    }
  }
</script>
