import mutations from "./mutations";
import actions from "./actions";
import getters from "./getters";


const state = {
  showSnackBar: false,
  success: false,
  snackBarMessage: "",
  color: "",
  snackBarHint: ""
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters
};
