<template>
<div class="section">
  <div class="main">
    <div class="banner_container">
      <v-row no-gutters class="tw-flex-col-reverse md2:tw-flex-row">
        
        <v-col
          cols="12"
          md="6"
          class="tw-flex tw-items-center column_two tw-justify-center"
        >
          <img :src="image" alt="" />
        </v-col>
        <v-col
          cols="12"
          md="6"
          class="tw-flex tw-items-center column_one tw-justify-center"
        >
          <div class="tw-p-6">
            <h1 class="tw-pt-8 md2:tw-pt-0">Build a merch for free</h1>
            <h5>
              Design and fund-raise with custom merch designs in few clicks
            </h5>
             
              <div @click="$router.push('/build')" class="tw-px-5 try_merch tw-flex tw-cursor-pointer tw-items-center">
                <span class="py-4">Create Merch Now</span>
                <v-icon size="18" color="#514A41" class="tw-pl-1"
                  >mdi-arrow-top-right</v-icon
                >
              </div>
          </div>
        </v-col>
      </v-row>
    </div>
  </div>

       
</div>
</template>

<script>
import {buildMerch} from "@/assets/cloudinary";

export default {
    name:"BuildMerchBanner",
  data(){
      return{
        image: buildMerch
      }
  }
};
</script>

<style lang="scss" scoped>
.section{
  margin: 50px 0 100px;
}
.main { 
  max-width: 1600px;
  margin: auto;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #FFFFFF;
     @media (max-width:767px) {      
      font-size: 38px;
      line-height: 100%;
    }
  }
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 120%;
    color: #ffffff;
    max-width: 316.67px;
    padding: 16px 0;
  }
  span {
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 120%;
    letter-spacing: -0.02em;
    color: #322412;
  }
}
.banner_container {
  width: 85%;
  background: #00050D;
  border-radius: 64px;
  margin: auto;
  position: relative;
}
.try_merch{
background: #FFFFFF;
border-radius: 50px;
width: fit-content;
span{
  font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 120%;
letter-spacing: -0.02em;
color: #00050D;
@media (max-width:767px) {      
      font-size: 14px;
    }
}
}
.column_two {
  img {
    margin: 50px 0 0 80px;
    @media (max-width: 767px) {
       margin: 50px 0 0 ;
    }
  }
}
.column_one {
  div {
    max-width: 400px;
  }
}
</style>