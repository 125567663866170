export const womanImg = 'https://res.cloudinary.com/kneenk/image/upload/v1673946812/landing%20page%20images/Frame_12957_znqbhe.png'
export const womanImgTwo = 'https://res.cloudinary.com/kneenk/image/upload/v1691754120/landing%20page%20images/Frame_12958_2_idi9q5.png'
export const wavyLine = 'https://res.cloudinary.com/kneenk/image/upload/v1673947018/landing%20page%20images/Sketch-annotation-element-stroke-abstract-line-wavy-1_fg2riz.png'
export const teesOne = 'https://res.cloudinary.com/kneenk/image/upload/v1673948318/landing%20page%20images/kneenk-front-demo_1_ihzyu2.png'
export const teesTwo ='https://res.cloudinary.com/kneenk/image/upload/v1673948501/landing%20page%20images/kneenk-front-demo_2_bttqn7.png'
export const teesThree = 'https://res.cloudinary.com/kneenk/image/upload/v1673948629/landing%20page%20images/kneenk-front-demo_1_1_xpdacs.png'
export const winksPlusLine = 'https://res.cloudinary.com/kneenk/image/upload/v1673949377/landing%20page%20images/Sketch-annotation-element-stroke-abstract-winks-plus-line_lw6mfd.png'
export const fundRaiserLine = 'https://res.cloudinary.com/kneenk/image/upload/v1673949683/landing%20page%20images/Rectangle_43_bfwuvg.png'
export const fundraiserComingText = 'https://res.cloudinary.com/kneenk/image/upload/v1673950044/landing%20page%20images/COMING_zzimmo.png'
export const creatorLine = 'https://res.cloudinary.com/kneenk/image/upload/v1673950356/landing%20page%20images/Rectangle_44_jlh8yc.png'
export const buildMerch = 'https://res.cloudinary.com/kneenk/image/upload/v1673959930/landing%20page%20images/Shirt_edit_nqskvy.png'
export const creatorComingText = 'https://res.cloudinary.com/kneenk/image/upload/v1673960220/landing%20page%20images/COMING_jozhsw.png'
export const shirtBagImage = 'https://res.cloudinary.com/kneenk/image/upload/v1673962619/landing%20page%20images/Group_13049_araatl.png'
export const classicTees = 'https://res.cloudinary.com/kneenk/image/upload/v1673970017/landing%20page%20images/Frame_43_kslakl.png'