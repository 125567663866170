export default {
  
    getProfileInfoSuccess:(state, data) => {
        state.profileInfo = data
    },
    editProfileInfoSuccess:(state, data) => {
        state.profileInfo = data
        let userData =  JSON.parse(localStorage.getItem('userData'))
        userData.first_name = data.first_name
        userData.last_name = data.last_name
        localStorage.setItem('userData', JSON.stringify(userData))
    },
    getAllUserOrdersSuccess:(state, data) => {
        state.allOrders = data
    },
    getAllSavedDesignsSuccess:(state, data) => {
        state.allSavedDesigns = data
    },
    deleteSavedDesignSuccessful:(state, data) =>{
        state.allSavedDesigns = state.allSavedDesigns.filter(item=> item.id !== data)
    }

}
