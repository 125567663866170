<template>
  <section class="section">
    <div class="main">
      <div class="header">
        <span class=" tw-mb-4">Why you should use kneenk!</span>
        <h1>Kneenk is the quickest way to customize and order quality merch</h1>
      </div>
      <v-row>
        <v-col sm="12" md="4" cols="12">
          <div class="column column_one">
            <h3>
              Tees
            </h3>
          </div>
        </v-col>
        <v-col sm="12" md="4" cols="12">
          <div class="column column_two">
            <h3>Wristbands</h3>
          </div>
        </v-col>
        <v-col sm="12" md="4" cols="12">
          <div class="column column_three">
            <h3>Hoodies</h3>
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
export default {
  name: "WhyKneek",
};
</script>

<style lang="scss" scoped>
.section{
    max-width: 1600px;
  margin: 0 auto 100px ;
}
.main {
  width: 85%;
  margin: auto;
  h1 {
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.04em;
    color: #322412;
    max-width: 700px;
    margin-bottom: 60px;
    @media (max-width:767px) {      
      font-size: 32px;
      line-height: 100%;
    }
  }
  span{
      font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 100%;
        text-align: center;
        letter-spacing: -0.01em;
        color: #999999;

  }
}
.header{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

}
.column {
  position: relative;
  border-radius: 32px;
  height: 500px;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 32px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #10260b;
    position: absolute;
    bottom: 50px;
    left: 30px;
  }
}
.column_one {
  background: url("../../assets/landingPage/Tees.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.column_two {
  background: url("../../assets/landingPage/Wristbands.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.column_three {
  background: url("../../assets/landingPage/Hoodies.svg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
</style>