<template>
  <div class="section">
    <div class="main">
    <v-row no-gutters>
      <v-col
        cols="12"
        md="6"
        class="tw-flex tw-items-center column_one tw-justify-center lg:tw-justify-start"
      >
        <div class="tw-p-5">
          <h3 class="tw-pt-8 md2:tw-pt-0">Start creating merch now!</h3>
          <h5 class=" tw-pt-6">
            Easiest and fastest way to create merch and sell on a large scale.
          </h5>
          <div class="tw-flex tw-items-center tw-mt-4" v-if="!getLogInStatus">
            <v-btn to="/build" text class="button">Get Started</v-btn>
          </div>
            <div class="tw-flex tw-items-center tw-mt-4" v-else>
                <v-btn to="/build" text class="button">Create Merch</v-btn>
            </div>
        </div>
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="tw-flex tw-items-center column_two tw-justify-end tw-pl-36"
      >
        <img :src="image" alt="" />
      </v-col>
    </v-row>
    </div>
  </div>
</template>

<script>
import {shirtBagImage} from "@/assets/cloudinary";
import isUserLoggedIn from "@/mixins/isUserLoggedIn";

export default {
    mixins: [isUserLoggedIn],
  data(){
    return{
      image: shirtBagImage
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  background: #ffc205;
  @media (max-width:767px) {      
      background: #00050D;
    }
  
}
.main{
  max-width: 1600px;
  margin: auto;
}
.column_one {
    div{
        // max-width: 450px;
    }
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 100%;
    letter-spacing: -0.04em;
    color: #00050d;
    max-width: 450px;
     @media (max-width:767px) {      
      font-size: 40px;
      line-height: 100%;
      color: #FFFFFF;
    }
  }
  h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 140%;
    color: #00050d;
    max-width: 400px;
    @media (max-width:767px) {      
      color: #FFFFFF;
      font-size: 16px;
    }
  }
}
.button{
    background: #FFFFFF;
    box-shadow: 0px 24px 40px #F6A303;
    border-radius: 8px;
    text-transform: capitalize;
    letter-spacing: 0;
    padding: 50px;
     @media (max-width:767px) {      
      box-shadow: none;
    }
}
</style>