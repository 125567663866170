import actions from "./actions";
import getters from "./getters";
import mutations from "./mutations";

const user = JSON.parse(sessionStorage.getItem("userData"));
const userToken = JSON.parse(sessionStorage.getItem("userToken"));

const initialState = userToken
  ? { status: { loggedIn: true }, user, isPresent: false }
  : {
    status: { loggedIn: false },
    user: {
      email: "",
      first_name: "",
      last_name: "",
      exp: "",
      id: "",
      jti: "",
      name: "",
      token_type: "",
      user_id: ""
    },
    isPresent: false
  };

export default {
  namespaced: true,
  state: initialState,
  actions,
  mutations,
  getters
};
