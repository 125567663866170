<template>
  <div>
    <NewBanner />
    <Partners />
    <div class="imagePartners scroll"></div>
    <why-kneek />
    <div class="imageOne scroll"></div>
    <build-merch-banner />
    <div class="imageTwo scroll"></div>
    <testimonials />
    <fundraiser-section />
    <start-creating-section />
  </div>
</template>

<script>
import NewBanner from "./LandingPage/Banner.vue";
import StartCreatingSection from "./LandingPage/StartCreatingSection.vue";
import BuildMerchBanner from "./LandingPage/BuildMerchBanner.vue";
import WhyKneek from "./LandingPage/WhyKneek.vue";
import Partners from "./LandingPage/Partners.vue";
import FundraiserSection from "./LandingPage/FundraiserSection.vue";
import Testimonials from "./LandingPage/Testimonials.vue";

export default {
  name: "KneekLandingPage",
  components: {
    NewBanner,
    StartCreatingSection,
    BuildMerchBanner,
    WhyKneek,
    Partners,
    FundraiserSection,
    Testimonials,
  },
};
</script>

<style scoped>
.imagePartners {
  background: url("../assets/landingPage/partners.svg")
  center;
  height: 40px;
  margin-top: -100px;
  margin-bottom: 200px;
}
.imageOne {
  background: url("../assets/landingPage/fundraise_sell_create.svg") repeat
    center;
  height: 40px;
  margin-top: 50px;
  margin-bottom: 100px;
}
.imageTwo {
  background: url("../assets/landingPage/review.svg") repeat center;
  height: 40px;
}

/* .scroll-text {
  animation properties
  -moz-transform: translateX(100%);
  -webkit-transform: translateX(100%);
  transform: translateX(100%);
  
  -moz-animation: my-animation 15s linear infinite;
  -webkit-animation: my-animation 15s linear infinite;
  animation: my-animation 15s linear infinite;
} */

/* for Firefox */
/* @-moz-keyframes my-animation {
  from { -moz-transform: translateX(100%); }
  to { -moz-transform: translateX(-100%); }
} */

/* for Chrome */
/* @-webkit-keyframes my-animation {
  from { -webkit-transform: translateX(100%); }
  to { -webkit-transform: translateX(-100%); }
}

@keyframes my-animation {
  from {
    -moz-transform: translateX(100%);
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
  to {
    -moz-transform: translateX(-100%);
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
  }
} */

.scroll{
  -webkit-animation: bannermove 15s linear infinite;
  -moz-animation: bannermove 15s linear infinite;
  -ms-animation: bannermove 15s linear infinite;
  -o-animation: bannermove 15s linear infinite;
  animation: bannermove 15s linear infinite;
}

/*keyframe animations*/

@keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2500px;
  }
}
@-moz-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2500px;
  }
}

@-webkit-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2500px;
  }
}
@-ms-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2500px;
  }
}

@-o-keyframes bannermove {
  0% {
    margin-left: 0px;
  }
  100% {
    margin-left: -2500px;
  }
}
</style>
