import mutations from "@/store/buildMerch/mutations";
import getters from "@/store/buildMerch/getters";
const state = {
    selectedColor: '#FFF',
    fontSize: '24',
    textColor: '#FFF',
    fontFamily: 'DM Sans',
    textAlign: 'Left',
    letterAngle: '',
    customText: [],
    sizes: [
        '12',
        '14',
        '16',
        '20',
        '24',
        '28',
        '32',
        '36',
        '48',
        '56',
        '100'
    ],
    fontLetterSpacing: 24,
    image: '',
    btnInView: 'Merch',
    designTitle: '',
    exportCanvasDrawer: false,
    canvasImageURL: '',
    canvas: null,
    productName: 'Polo Hands-off Tee',
    selectedText:{},
    updatedText:'',
    isBack:false,
    showStyle:false,
  zoomValue:null,
    canvasFrontJson:{},
    canvasBackJson:{},
    requestDesignDetails: {}
}

export default {
    namespaced: true,
    state,
    mutations,
    getters
}
