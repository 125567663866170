import {getProfileInfo , editProfileInfo, getAllUserOrders, getAllSavedDesigns, deleteSavedDesign } from "@/services/user-profile";

export default {
  async getProfileInfo({ commit }) {
    return getProfileInfo().then(
      res => {
        commit("getProfileInfoSuccess", res.data);
        return Promise.resolve(res.data);
      },
      error => {
        // commit("loginFailure");
        return Promise.reject(error);
      }
    );
  },
  async editProfileInfo({ commit }, data) {
    return editProfileInfo(data).then(
      res => {
        commit("editProfileInfoSuccess", res.data);
        return Promise.resolve(res.data);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  async getAllUserOrders({ commit }) {
    return getAllUserOrders().then(
      res => {
        console.log(res.data)
        commit("getAllUserOrdersSuccess", res.data);
        return Promise.resolve(res.data);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  async getAllSavedDesigns({ commit }) {
    return getAllSavedDesigns().then(
      res => {
        console.log(res.data)
        commit("getAllSavedDesignsSuccess", res.data);
        return Promise.resolve(res.data);
      },
      error => {
        return Promise.reject(error);
      }
    );
  },
  async deleteSavedDesign({ commit },saved_design_id) {
    return deleteSavedDesign(saved_design_id)
      .then(res => {
        commit("deleteSavedDesignSuccessful", saved_design_id);
        // console.log(res)
      })
      .catch(err => {
        return Promise.reject(error);
      });
  },
};
