<template>
  <section class="section">
    <div class="main">
      <v-row>
        <v-col sm="12" md="6" cols="12">
          <div class="column column_one">
            <div class="button">
              <v-btn >coming soon</v-btn>
            </div>
            <div class="content_one">
              <img class="plus" :src="images.winksPlusLine" alt="" />

              <h3>
                Start a<br />
                Fundraiser
              </h3>
              <img class="line tw--mt-3" :src="images.fundRaiserLine" alt="" />
            </div>
             <p class="px-2">Design and fund-raise with custom merch designs in few clicks</p>
             
             <img class="outline_text" :src="images.fundraiserComingText" alt="" />
          </div>
        </v-col>
        <v-col sm="12" md="6" cols="12">
          <div class="column column_two">
            <div class="button">
              <v-btn>coming soon</v-btn>
            </div>
            <div class="content_two">
              <img class="plus" :src="images.winksPlusLine" alt="" />
              <h3>
                Sell as a<br />
                Creator
              </h3>
              <img class="line tw--mt-3" :src="images.creatorLine" alt="" />
            </div>
            <p class="px-2">Sell merch you customize on kneenk easily and quickly</p>
            <img class="outline_text" :src="images.creatorComingText" alt="" />
          </div>
        </v-col>
      </v-row>
    </div>
  </section>
</template>

<script>
import {winksPlusLine, fundRaiserLine, fundraiserComingText, creatorLine, creatorComingText} from "@/assets/cloudinary";

export default {
  name: "FundraiserSection",
  data(){
    return{
      images: {
        winksPlusLine,
        fundRaiserLine,
        fundraiserComingText,
        creatorLine,
        creatorComingText
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.section {
  max-width: 1600px;
  margin: 100px auto;

}
.main {
  width: 85%;
  margin: auto;
}

.column {
  padding-top: 50px;
  position: relative;
  height: 500px;
  background: #322412;
  border-radius: 64px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: auto;
  h3 {
    font-style: normal;
    font-weight: 700;
    font-size: 56px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.06em;
    color: #ffffff;
     @media (max-width:767px) {      
      font-size: 48px;
      line-height: 100%;
    }
  }
  p{
      font-family: 'DM Sans';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 120%;
text-align: center;
color: #FFFFFF;
margin-top: 24px;
max-width: 312px;
  }
  .outline_text{
      position: absolute;
      bottom: 0;
  }
}
.button ::v-deep .theme--light.v-btn.v-btn--has-bg {
    background: #999188;
border-radius: 8px;
}
.content_one {
  margin-top: 100px;
  position: relative;
  .plus {
    position: absolute;
    top: -12px;
    left: 19px;
    @media (max-width:767px) {      
      left: 25px;
    }
  }
}
.content_two {
  margin-top: 100px;
  position: relative;
  .plus {
        top: -10px;
    position: absolute;
    left: -30px;
    @media (max-width:767px) {      
      left: -17px;
    }
  }
}
</style>